import '../index.css';

import classNames from 'classnames';
import { graphql, navigate } from 'gatsby';
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import { FC, Fragment, ReactNode, useMemo } from 'react';

import { BlockTree } from '@dogtainers/cms-blocks';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useLocation } from '@reach/router';

import { HEADER_HEIGHT } from '../components/header/header';
import Metas from '../components/metas';
import { ResponsiveContext, SiteLayout } from '../layouts/site.layout';
import { structuredDataFAQ } from '../seo/faq';
import { CmsPage } from '../types';
import { generateBreadcrumbsList, stripOutsideSlashes } from '../utils';
import { TemplateProps } from './template.types';

const transparentNavPages = ['home'];

const useStyles = makeStyles(() => {
  return {
    root: {
      margin: 0,
      padding: 0,
    },
    transparentNav: {
      transform: `translateY(-${HEADER_HEIGHT.MOBILE})`,

      '@media (min-width:768px)': {
        transform: `translateY(-${HEADER_HEIGHT.DESKTOP})`,
      },
    },
  };
});

// Fetch cms page data
export const query = graphql`
  query getPage($pageId: ID) {
    cms {
      pageBuilder {
        getPublishedPage(id: $pageId) {
          data {
            id
            title
            path
            content
            settings {
              seo {
                title
                description
                meta {
                  name
                  content
                }
              }
            }
          }
        }
      }
    }
  }
`;

type PageTemplateProps = TemplateProps & {
  data: {
    cms: {
      pageBuilder: {
        getPublishedPage: {
          data: CmsPage;
        };
      };
    };
  };
  children: ReactNode;
};

export const Page: FC<PageTemplateProps> = ({ children, ...templateProps }) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const {
    data: { cms },
    pageContext: { pageId },
  } = templateProps;

  const pageData = cms.pageBuilder.getPublishedPage.data;
  const cmsMeta = cms.pageBuilder.getPublishedPage.data.settings.seo;

  const isHome = stripOutsideSlashes(pageData.path) === 'home';

  const isFAQ =
    stripOutsideSlashes(pageData.path) === 'frequently-asked-questions';

  const breadcrumbs = useMemo(
    () => generateBreadcrumbsList(pathname),
    [pathname],
  );

  return (
    <Fragment>
      {isFAQ && <Metas scriptContent={structuredDataFAQ} />}
      <SiteLayout
        isHome={isHome}
        title={pageData.title}
        cmsMeta={cmsMeta}
        {...templateProps}
      >
        <BreadcrumbJsonLd itemListElements={breadcrumbs} />
        <ResponsiveContext.Consumer>
          {({ breakpoint }) => (
            <Box
              id="box-page-template"
              className={classNames({
                [classes.transparentNav]: transparentNavPages.includes(pageId),
              })}
            >
              {pageData.content.elements.map((element) => (
                <BlockTree
                  key={element.id}
                  element={element}
                  breakpoint={breakpoint}
                  navigate={navigate}
                />
              ))}
            </Box>
          )}
        </ResponsiveContext.Consumer>
      </SiteLayout>
    </Fragment>
  );
};

export default Page;
