export const structuredDataFAQ = {
  '@context': 'https://schema.org/',
  '@type': 'FAQPage',
  mainEntity: [
    {
      '@type': 'Question',
      name: 'Where are you accepting pets?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'We are accepting pets across Australia. We own and operate our retreats in Brisbane West and Brisbane South, there will be more locations available soon as we expand our offerings.',
      },
    },
    {
      '@type': 'Question',
      name: 'Are you open during public holidays?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'We are open all year round except for the following holidays, Good Friday, Easter Sunday, Christmas Day, Boxing day and New year’s day.',
      },
    },
    {
      '@type': 'Question',
      name: 'What are your opening hours?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Our Brisbane facilities opening hours are:  Monday-Friday 7:30am-10am & 2:30pm-4pm  Saturday 7:30am-10am  Sunday 4pm-5pm  Public holidays – 3pm-4pm',
      },
    },
    {
      '@type': 'Question',
      name: 'What is a pet boarding service?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: "Pet boarding service is similar to checking your pet into a hotel. Pet boarding provides pet owners peace of mind when they can’t take care of their pets. Pets are looked after by professionals who are familiar with pet’s behaviours.  The Doolittle's boarding facilities are designed to give a homey feeling to the pets. Your pet will also enjoy premium “room service” with meals from Royal Canin and daily entertainment of walks or playtime.",
      },
    },
    {
      '@type': 'Question',
      name: 'What services do you provide?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Standard Pet Boarding Our standard boarding services provide accommodation for your pet while you are away. Each stay includes a super-premium Royal Canin diet, bedding, toys and daily exercise or playtime for each fur baby. We offer day care, standard boarding stays, long-term boarding stays and special needs.  Additional playtime If you would like extra pampering for your pet or have a high-energy breed we can provide additional playtime or exercise. This service includes a one-on-one 15-minute session of your choice, and can be given to your pet anytime you need during their stay.  Pet transportation We offer pick up and delivery services in Brisbane. Our Brisbane Airport arrival and departure lounge offer a shuttle to and from the retreat of your choice. We can offer this service one way or return depending on your requirements.',
      },
    },
    {
      '@type': 'Question',
      name: 'Are there any prerequisites for my pet to board at Dolittles?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'We require a minimum C5 vaccination for dogs and F3 vaccination for cats to stay with us.',
      },
    },
    {
      '@type': 'Question',
      name: 'Do you provide pet transportation?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Yes, we provide pet pick up and delivery in Brisbane. Our Brisbane Airport arrival and departure lounge offer a shuttle to and from the retreat of your choice. We can offer this service one way or return depending on your requirements.',
      },
    },
    {
      '@type': 'Question',
      name: 'Who will be looking after my pet?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Our team has over 50 years of combined experience. Our team has chosen this field as their passion in life and love expressing this through their work.',
      },
    },
    {
      '@type': 'Question',
      name: 'How much does a pet kennel at Brisbane cost per day?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'For our Brisbane pet boarding facilities, we charge from $36 for Dog Boarding per day during off-peak periods and $40 during peak periods. For cat boarding, we charge from $30 during off-peak periods and $35 during peak periods. Please note that all peak, holiday & home pickup or delivery bookings require a 50% deposit time of booking.',
      },
    },
    {
      '@type': 'Question',
      name: 'How much does dog boarding cost during Christmas and Easter?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'For Christmas and Easter holidays, single non-social dog boarding costs from $85 and $170 per day for a pair of unsocial dogs. For other boarding rates, please contact our team to enquire.',
      },
    },
    {
      '@type': 'Question',
      name: 'What payment method do you provide?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Our payment methods include payment over the phone or via bank transfer.',
      },
    },
    {
      '@type': 'Question',
      name: 'How much should I pay for the deposit?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'A 50% deposit is required to secure your pet boarding booking. For further details, please see our payment policy.',
      },
    },
    {
      '@type': 'Question',
      name: 'What is the cancellation policy?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'If you need to cancel your booking during off-peak periods, your refund will be in the form of credits that can be used within 6 months from the date of cancellation. (A $25 administrative fee may apply.)    During peak periods such as Christmas, Easter, Public and School Holidays, we would require a 50% non-refundable deposit to secure your booking.',
      },
    },
    {
      '@type': 'Question',
      name: 'Do you offer discounts for long-term stays?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Yes, we can offer discounts for long-term stays, please contact us with your details and we’ll let you know what we can do.',
      },
    },
    {
      '@type': 'Question',
      name: 'What happens if something happens to my pet and they need a vet?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'If we notice something doesn’t seem right with pets, we will make the first call to the owner (see what the owner has requested for us to do). If the pet owner can’t be reached, we would then call the emergency contact on file. If we still don’t get through to the owner, we do what’s best for the animal and if that requires vet care then we will go ahead with organising it.    For our boarding facility at Brisbane South, there’s a Greencross Vet Clinic 2 doors down that we seek assistance regularly.    For our boarding facility at Brisbane West, we usually contact a mobile vet (PACvets) who is very reliable and always happy to lend a hand.',
      },
    },
    {
      '@type': 'Question',
      name: 'What if my dogs and cats require medication while in your care?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'If your pet requires medication during their stay, please let us know. Our team is experienced in administering all types of medication including injections and are happy to assist.',
      },
    },
    {
      '@type': 'Question',
      name: 'If my dog can jump fences, can he/she escape?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'We have a staff member supervising our guests playing at all times, but if you know your dogs can climb, jump or dig out of fences, please let us know so we are aware and can keep an extra close eye on them while playing.',
      },
    },
    {
      '@type': 'Question',
      name: 'Will my dog or cat be sharing with another animal?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'We offer both shared and guaranteed individual housing for our canine guests, please let us know when you make your booking which you prefer. We do not share accommodation for cats unless they are from the same family and you would prefer them housed together.',
      },
    },
    {
      '@type': 'Question',
      name: 'Are there individual rooms for pets?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Yes!  For dogs: We provide single and double rooms.  For single rooms, we usually house 1 dog, a maximum of 2 dogs can be housed together during peak period or if you would prefer your pets homed together. For double rooms, we usually house 2 dogs, a maximum of 3 dogs can be housed together during peak period or if you would prefer your pets homed together.   We house dogs together if they are from the same family and are social with one another, otherwise we socialise dogs from different families with other dogs that are of the same temperament, energy level and size.  For cats: We provide single, double and triple rooms.  For single rooms, we house 1 cat, a maximum of 2 cats can stay together if the owner requests it.  For double rooms, we house 2 cats, a maximum of 3 cats can stay together if the owner requests it.  For triple rooms, we house 3 cats, a maximum of 4 cats can stay together if the owner requests it.  Cats aren’t as social at all, unless they are from the same family. Therefore, unless the owner requests the cats to be housed together, they usually have their own space.',
      },
    },
    {
      '@type': 'Question',
      name: 'What is inside the rooms?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Cat catteries: Our cat accommodation is quiet and private, with an individual window overlooking the property to keep your little one entertained. Inside the rooms, there are two levels to play in and it’s located close to reception, the cuddles are abundant for these little ones! We don’t mix cats from different families, however, we can house family cats together or separately depending on your needs.  Dog kennels: Our dog accommodation includes a large size bed that’s off the ground with a comfortable blanket to snuggle with. There’s also an abundance of toys and treats such as pull ropes, chew toys, Kong toys with treats inside to keep the mind going.',
      },
    },
    {
      '@type': 'Question',
      name: 'My dog is usually fed twice a day, can you do this?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Yes! Please let us know when you are making your booking and we will feed your little one to your schedule.',
      },
    },
    {
      '@type': 'Question',
      name: 'Will I get updates on my pet while they are with you?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: "We endeavour to get photos of all our guests on our Facebook page, however, if you can't see photos of your pet and would like an update/photos please feel free to call us anytime during your pet’s stay.",
      },
    },
    {
      '@type': 'Question',
      name: 'Do you accept entire (not desexed) or in-heat pets?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Yes, we do accept entire and in-heat animals.',
      },
    },
    {
      '@type': 'Question',
      name: 'Can I bring in my own bedding?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'While we do provide all bedding, yes you are welcome to bring in your own.',
      },
    },
    {
      '@type': 'Question',
      name: 'My pet requires a very specific, regimented routine and must be cared for this way or he/she will stress. Can you do this?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Yes! Not a problem. All we ask is that you give us as much detail as possible for your pet’s requirements and what process you’d like us to follow.',
      },
    },
    {
      '@type': 'Question',
      name: 'My dog is timid and can react badly to strange situations, how will you cope with this?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Our Team Leaders are very experienced with a variety of dog personalities. If your dog is timid or unsure, we will put in place measures to make your pet’s stay easier. Please talk to us and let us know your pet’s preferences and dislikes. In these cases, we highly recommend bringing in a favourite toy or blanket that smells like you (or home) as we have found this helps a great deal in new environments.',
      },
    },
    {
      '@type': 'Question',
      name: 'What should I bring for my pet?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Here’s a handy checklist for things that you can prepare for your pets:  Bedding if you prefer (this is provided) Document proof of vaccines Medications (If required) Pet’s own food (If required) Jackets through winter and cooling mats through summer (we have a few on-site as well as shell pools)',
      },
    },
    {
      '@type': 'Question',
      name: 'What activities would my pet participate in?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'There is daily exercise or playtime during their stay. For dogs, we usually take them out for a walk around our retreat. We have 6 large running yards for exercise. This is heaven for those who love to play ball and chase a stick or just love a general run around.',
      },
    },
    {
      '@type': 'Question',
      name: 'Can you bathe my dog before pick up? Does the hydrobath require extra costs?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'We offer a complimentary hydro bath before the dog goes home. However, if you would like us to add extra baths during the stay, it will be $10 per bath.    We use Fido’s premium products in our hydro baths, heated water and a choice of shampoos. Please let us know if your pet has special needs and we will do our best to accommodate (or you are welcome to bring in your own shampoo).',
      },
    },
    {
      '@type': 'Question',
      name: 'Does the hydrobath require extra costs?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'We offer three levels of hydrobath: Standard Best for short coats, this is a standard bath and towel dry.  Standard  + towel dry Best for medium to long-coated dogs, this option includes a standard bath with a very thorough towel dry.  Standard  + blow dry Best for medium to long-coated dogs, this option includes a full blow dry making sure your pooch is dry from top to bottom.  Grooming We offer standard grooming services at our Kholo facility and can groom or clip your pet during their holiday.',
      },
    },
    {
      '@type': 'Question',
      name: 'Can I come in and see the kennels and cattery?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Yes, customers are more than welcome to come in to meet our friendly staff and have a look at the facility and where their fur-friend would be staying.',
      },
    },
    {
      '@type': 'Question',
      name: 'Can I take my pet on holiday?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Yes, our pet transport partners at Dogtainers would be able to help with domestic and international pet travels or relocations, geared with 50 years of experience, their pet travel specialists can create tailored pet travel solutions to suit your needs.',
      },
    },
    {
      '@type': 'Question',
      name: 'Do dogs and cats miss you when you go on holiday?',
      acceptedAnswer: {
        '@type': 'Answer',
        text: 'Your dog and cat will miss you while you’re away. Pets are usually used to a routine when you’re around, and when certain activities don’t happen, it increases their anxiety. It would be better if you have someone experienced taking care of them and let them know your pets daily routine.',
      },
    },
  ],
};
