import { FC } from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  title?: string;
  metaName?: string;
  metaContent?: string;
  scriptType?: string;
  scriptContent?: Record<string, unknown>;
  robotsContent?: string;
  linkRel?: string;
  linkHref?: string;
};

// credits to Natalija
const Metas: FC<Props> = (props: Props) => {
  const {
    title,
    metaName = 'description',
    metaContent,
    scriptType = 'application/ld+json',
    scriptContent,
    // robotsContent = '%REACT_APP_ROBOTS_CONTENT%',
    linkRel = 'canonical',
    linkHref,
  } = props;

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {/* <meta name="robots" content={robotsContent} /> */}
      {linkHref && <link rel={linkRel} href={linkHref} />}
      {metaContent && <meta name={metaName} content={metaContent} />}
      {!!scriptContent && (
        <script type={scriptType}>{JSON.stringify({ scriptContent })}</script>
      )}
    </Helmet>
  );
};

export default Metas;
